import Header from './Header'
import './css/header.css'
import './css/base.css'
import './css/info.css'
import './css/footer.css'
// import InfoSection from './InfoSection';
import whiteAppleLink from './Assets/whiteAppleLink.png'
import Footer from './Footer'
import PrivacyPolicy from './PrivacyPolicy'

function App() {
  const local = window.location.href
  if(local.toLowerCase().includes('privacy')) {
    return <PrivacyPolicy />
  }
  return (
    <>
    <div className='page__container'>
      <div className='page'>
        <Header />
        {/* <InfoSection /> */}
        {/* <div className='' 
        //  onClick={() => alert("Coming soon")}
        > */}
          <a
          className='info cursor-pointer'
          href='https://apps.apple.com/us/app/tee-total/id6469518972'
          target="_blank" rel="noopener noreferrer"
          >
          <img
            src={whiteAppleLink}
            alt="Apple Download Button"
            />
          </a>
        {/* </div> */}
      </div>
    </div>
    <Footer />
    </>
  );
}

export default App;
