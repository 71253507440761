const Footer = () => {
    const d = new Date();
    const year = d.getFullYear();
    return (
        <div className="footer">
            <div>
                Contact/Support:{" "} 
                <a className="color-white" href = "mailto: TeeTotalAppHelp@gmail.com">TeeTotalAppHelp@gmail.com</a>
            </div>
            <div>
                Image Attribution:{" "} 
                <a className="color-white" href="https://www.vecteezy.com/free-png/app-store">App Store PNGs by Vecteezy</a>
            </div>
            <div className="flex-row">
            <p className="footer-copyright">&copy;</p>Thread Booking LLC {year}
            </div>
        </div>
    )
}

export default Footer;