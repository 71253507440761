import logo from './Assets/logo.png'
const Header = () => {
    return (
        <div className="header">
            <div className="header__logo">
                <img
                src={logo}
                alt="Logo"
                />
            </div>
            <div className="header__top">
                Tee Total
            </div>
            <div className="header__bottom">
                Golf Score Card On iPhone & Apple Watch
            </div>
        </div>
    )
}

export default Header;